import * as Sentry from '@sentry/nextjs'
import { type SagaIterator } from 'redux-saga'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import {
    getYandexMetrikaCounterId,
    getYandexMetrikaId,
} from 'src/utils/helpers'

import * as api from './api'
import { actions } from './slice'
import {
    type ContactWithUsPayload,
    type SendNotifyProductionReadyPayload,
    type SupplierEnrolmentPayload,
} from './types'
import {
    IContactsWithUs,
    INotifyProductionReadyForm,
} from '@/lib/CSProvider/types'

function* sendNotifyProductionReady(action: {
    payload: SendNotifyProductionReadyPayload
}): SagaIterator {
    try {
        const currentUrl: string = document.location.href
        const data: INotifyProductionReadyForm = {
            ...action.payload,
            visitorParams: {
                url: currentUrl,
                _ym_uid: getYandexMetrikaId(),
                _ym_counter: getYandexMetrikaCounterId(),
            },
        }
        yield call(api.sendNotifyProductionReady, data)
        yield put(actions.sendNotifyProductionReadySuccess())
    } catch (error) {
        let errorData = error.message
        if (Array.isArray(error.response.data.message)) {
            errorData = error.response.data.message.map(
                (i: { property: string }) => i.property
            )
        } else {
            console.warn(error)
            Sentry.captureException(error)
        }
        yield put(actions.sendNotifyProductionReadyFailure(errorData))
    }
}

export function* watchNotifyProductionReady(): SagaIterator {
    yield takeEvery(
        actions.sendNotifyProductionReady,
        sendNotifyProductionReady
    )
}

function* sendSupplierEnrolment(action: {
    payload: SupplierEnrolmentPayload
}): SagaIterator {
    try {
        const currentUrl: string = document.location.href
        const data: IContactsWithUs = {
            ...action.payload,
            visitorParams: {
                url: currentUrl,
                _ym_uid: getYandexMetrikaId(),
                _ym_counter: getYandexMetrikaCounterId(),
            },
        }
        yield call(api.sendSupplierEnrolment, data)
        yield put(actions.sendSupplierEnrolmentSuccess())
    } catch (error) {
        let errorData = error.message
        if (Array.isArray(error.response.data.message)) {
            errorData = error.response.data.message.map(
                (i: { property: string }) => i.property
            )
        } else {
            console.warn(error)
            Sentry.captureException(error)
        }
        yield put(actions.sendSupplierEnrolmentFailure(errorData))
    }
}

export function* watchSupplierEnrolment(): SagaIterator {
    yield takeEvery(actions.sendSupplierEnrolment, sendSupplierEnrolment)
}

function* sendContactWithUs(action: {
    payload: ContactWithUsPayload
}): SagaIterator {
    try {
        const currentUrl: string = document.location.href
        const data: IContactsWithUs = {
            ...action.payload,
            visitorParams: {
                url: currentUrl,
                _ym_uid: getYandexMetrikaId(),
                _ym_counter: getYandexMetrikaCounterId(),
            },
        }
        yield call(api.sendContactWithUs, data)
        yield put(actions.sendContactWithUsSuccess())
    } catch (error) {
        let errorData = error.message
        if (Array.isArray(error.response.data.message)) {
            errorData = error.response.data.message.map(
                (i: { property: string }) => i.property
            )
        } else {
            console.warn(error)
            Sentry.captureException(error)
        }
        yield put(actions.sendContactWithUsFailure(errorData))
    }
}

export function* watchContactWithUs(): SagaIterator {
    yield takeEvery(actions.sendContactWithUs, sendContactWithUs)
}

export default function* watchHomePage(): SagaIterator {
    yield all([
        fork(watchNotifyProductionReady),
        fork(watchSupplierEnrolment),
        fork(watchContactWithUs),
    ])
}
