import { type SagaIterator } from '@redux-saga/core'
import { all, fork } from 'redux-saga/effects'

export function* watchInitializeApplication(): SagaIterator {
    return true
}

export default function* watchApplication(): SagaIterator {
    yield all([fork(watchInitializeApplication)])
}
