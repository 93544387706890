import axios, { type AxiosResponse } from 'axios'

import {
    type ContactWithUsPayload,
    type SendNotifyProductionReadyPayload,
    type SupplierEnrolmentPayload,
} from './types'

export const sendNotifyProductionReady = async (
    data: SendNotifyProductionReadyPayload
): Promise<AxiosResponse> => {
    return await axios.post('/api/notify-about-production-ready', data)
}

export const sendSupplierEnrolment = async (
    data: SupplierEnrolmentPayload
): Promise<AxiosResponse> => {
    return await axios.post('/api/supplier-enrolment', data)
}

export const sendContactWithUs = async (
    data: ContactWithUsPayload
): Promise<AxiosResponse> => {
    return await axios.post('/api/contact-with-us', data)
}
