import * as Sentry from '@sentry/nextjs'
import { type SagaIterator } from 'redux-saga'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import {
    getYandexMetrikaCounterId,
    getYandexMetrikaId,
} from 'src/utils/helpers'

import * as api from './api'
import { actions } from './slice'
import { type SendSelectionAssistPayload, type UploadPayload } from './types'
import { ISelectionAssist } from '@/lib/CSProvider/types'

function* sendSelectionAssist(action: {
    payload: SendSelectionAssistPayload
}): SagaIterator {
    try {
        const currentUrl: string = document.location.href
        const data: ISelectionAssist = {
            ...action.payload,
            visitorParams: {
                url: currentUrl,
                _ym_uid: getYandexMetrikaId(),
                _ym_counter: getYandexMetrikaCounterId(),
            },
        }
        yield call(api.sendSelectionAssist, data)
        yield put(
            actions.sendSuccessPayload({
                email: action.payload.email,
                phone: action.payload.phone,
            })
        )
    } catch (error) {
        let errorData = error.message
        if (
            error.response.status === 400 &&
            Array.isArray(error.response.data?.message)
        ) {
            errorData = error.response.data.message.map(
                (i: { property: string }) => i.property
            )
        } else {
            console.warn(error)
            Sentry.captureException(error)
        }
        yield put(actions.sendFailurePayload({ error: errorData }))
    }
}

export function* watchSendSelectionAssist(): SagaIterator {
    yield takeEvery(actions.sendPayload, sendSelectionAssist)
}

function* uploadFile(action: { payload: UploadPayload }): SagaIterator {
    try {
        const { data } = yield call(
            api.uploadImage,
            {
                formName: 'selection_assist',
                files: [action.payload.file.file],
            },
            percent => {
                action.payload.onUploadProgress(action.payload.file.id, percent)
            }
        )

        yield put(
            actions.uploadSuccessPayload({
                id: action.payload.file.id,
                url: data.url,
            })
        )
    } catch (error) {
        yield put(
            actions.uploadFailurePayload({
                id: action.payload.file.id,
                error: error.message,
            })
        )
        console.warn(error)
        Sentry.captureException(error)
    }
}

export function* watchUploadFile(): SagaIterator {
    yield takeEvery(actions.uploadPayload, uploadFile)
}

export default function* watchSelectionAssist(): SagaIterator {
    yield all([fork(watchSendSelectionAssist), fork(watchUploadFile)])
}
