import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
    type ContactWithUsFailurePayload,
    type ContactWithUsPayload,
    type ContactWithUsSuccessPayload,
    type InitialStateType,
    type SendNotifyProductionReadyFailurePayload,
    type SendNotifyProductionReadyPayload,
    type SendNotifyProductionReadySuccessPayload,
    type SupplierEnrolmentFailurePayload,
    type SupplierEnrolmentPayload,
    type SupplierEnrolmentSuccessPayload,
} from './types'

const initialState: InitialStateType = {
    notifyProductionReadyIsSend: false,
    notifyProductionReadyIsProcess: false,
    notifyProductionReadyError: null,
    supplierEnrolmentIsSend: false,
    supplierEnrolmentIsProcess: false,
    supplierEnrolmentError: null,
    contactWithUsIsSend: false,
    contactWithUsIsProcess: false,
    contactWithUsError: null,
}

export const homePageSlice = createSlice({
    name: 'homePage',
    initialState,
    reducers: {
        sendNotifyProductionReady(
            state: InitialStateType,
            _action: PayloadAction<SendNotifyProductionReadyPayload>
        ): void {
            state.notifyProductionReadyIsProcess = true
            state.notifyProductionReadyError = null
        },
        sendNotifyProductionReadySuccess(
            state: InitialStateType,
            _action: PayloadAction<SendNotifyProductionReadySuccessPayload>
        ): void {
            state.notifyProductionReadyIsSend = true
            state.notifyProductionReadyIsProcess = false
        },
        sendNotifyProductionReadyFailure(
            state: InitialStateType,
            action: PayloadAction<SendNotifyProductionReadyFailurePayload>
        ): void {
            state.notifyProductionReadyIsProcess = false
            state.notifyProductionReadyError = action.payload
        },
        sendSupplierEnrolment(
            state: InitialStateType,
            _action: PayloadAction<SupplierEnrolmentPayload>
        ): void {
            state.supplierEnrolmentIsProcess = true
            state.supplierEnrolmentError = null
        },
        sendSupplierEnrolmentSuccess(
            state: InitialStateType,
            _action: PayloadAction<SupplierEnrolmentSuccessPayload>
        ): void {
            state.supplierEnrolmentIsSend = true
            state.supplierEnrolmentIsProcess = false
        },
        sendSupplierEnrolmentFailure(
            state: InitialStateType,
            action: PayloadAction<SupplierEnrolmentFailurePayload>
        ): void {
            state.supplierEnrolmentIsProcess = false
            state.supplierEnrolmentError = action.payload
        },
        sendContactWithUs(
            state: InitialStateType,
            _action: PayloadAction<ContactWithUsPayload>
        ): void {
            state.contactWithUsIsProcess = true
            state.contactWithUsError = null
        },
        sendContactWithUsSuccess(
            state: InitialStateType,
            _action: PayloadAction<ContactWithUsSuccessPayload>
        ): void {
            state.contactWithUsIsSend = true
            state.contactWithUsIsProcess = false
        },
        sendContactWithUsFailure(
            state: InitialStateType,
            action: PayloadAction<ContactWithUsFailurePayload>
        ): void {
            state.contactWithUsIsProcess = false
            state.contactWithUsError = action.payload
        },
        destroy(): InitialStateType {
            return initialState
        },
    },
})

export const actions = homePageSlice.actions
