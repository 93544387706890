import * as Fathom from 'fathom-client'
import 'katex/dist/katex.min.css'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import posthog from 'posthog-js'
import 'prismjs/themes/prism-coy.css'
import * as React from 'react'
import 'react-notion-x/src/styles.css'
import { Provider } from 'react-redux'
import 'styles/global.css'
import 'styles/notion.css'
import 'styles/prism-theme.css'

import { wrapper } from '../src/store'
import { fathomConfig, fathomId, posthogConfig, posthogId } from '@/lib/config'

function MyApp({ Component, ...rest }: AppProps): JSX.Element {
    const router = useRouter()
    const { store, props } = wrapper.useWrappedStore(rest)

    React.useEffect(() => {
        function onRouteChangeComplete(): void {
            if (fathomId) {
                Fathom.trackPageview()
            }

            if (posthogId) {
                posthog.capture('$pageview')
            }
        }

        if (fathomId) {
            Fathom.load(fathomId, fathomConfig)
        }

        if (posthogId) {
            posthog.init(posthogId, posthogConfig)
        }

        router.events.on('routeChangeComplete', onRouteChangeComplete)

        return () => {
            router.events.off('routeChangeComplete', onRouteChangeComplete)
        }
    }, [router.events])

    return (
        <Provider store={store}>
            <Component {...props.pageProps} />
        </Provider>
    )
}

export default MyApp
