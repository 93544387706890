import * as Sentry from '@sentry/nextjs'

export const randomInteger = (min: number, max: number): number => {
    const rand = min - 0.5 + Math.random() * (max - min + 1)
    return Math.round(rand)
}

export interface ISSRApplication {
    application: {
        pageMeta: {
            title: string
            statusError: 404 | 500 | null
        }
    }
}

export const parseSSRState = (html: string): ISSRApplication | undefined => {
    const regSSRState =
        /<script>window.WIDGET_DATA = (.*?);window.WIDGET_STATE_DATA/

    const SSRState = regSSRState.exec(html)

    if (Array.isArray(SSRState) && SSRState[1]) {
        try {
            return JSON.parse(SSRState[1]) as ISSRApplication | undefined
        } catch (error) {
            Sentry.captureException(error)
        }
    }
}

export const parseSSRVersion = (html: string): string | undefined => {
    const regSSRState = /\/index.(.*?).js/
    const SSRState = regSSRState.exec(html)

    if (Array.isArray(SSRState) && SSRState[1]) {
        return SSRState[1]
    }
}

export const getYandexMetrikaCounterId = (): number | null => {
    if (typeof window !== 'undefined') {
        if (typeof window['Ya'] !== 'undefined' && window['Ya'].Metrika2) {
            for (const key in window['Ya'].Metrika2.counters()) {
                const counter = window['Ya'].Metrika2.counters()[key]
                if (counter?.id) {
                    return counter.id as number
                }
            }
        }
    }
    return null
}

export const getYandexMetrikaId = (): string | null => {
    if (typeof window !== 'undefined') {
        if (window['_ym_uid']) {
            return window['_ym_uid'] as string
        }
        const cookies = document.cookie.split(';')
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim()
            if (cookie.startsWith('_ym_uid=')) {
                return cookie.substring('_ym_uid='.length)
            }
        }
    }
    return null
}
