import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
    type InitialStateType,
    type SendSelectionAssistFailurePayload,
    type SendSelectionAssistPayload,
    type SendSelectionAssistSuccessPayload,
    type SetProgressPercentPayload,
    type UploadFailurePayload,
    type UploadPayload,
    type UploadSuccessPayload,
    type UnsupportedUploadPayload,
} from './types'

const initialState: InitialStateType = {
    isProcess: false,
    isSend: false,
    images: [],
    unsuportedFiles: [],
    progress: {},
    sendToEmail: null,
    sendToPhone: null,
    error: null,
}

export const selectionAssistSlice = createSlice({
    name: 'selectionAssist',
    initialState,
    reducers: {
        sendPayload(
            state: InitialStateType,
            _action: PayloadAction<SendSelectionAssistPayload>
        ): void {
            state.isProcess = true
            state.isSend = false
            state.error = null
        },
        sendSuccessPayload(
            state: InitialStateType,
            action: PayloadAction<SendSelectionAssistSuccessPayload>
        ): void {
            state.isProcess = false
            state.isSend = true
            state.sendToEmail = action.payload.email
            state.sendToPhone = action.payload.phone
        },
        sendFailurePayload(
            state: InitialStateType,
            action: PayloadAction<SendSelectionAssistFailurePayload>
        ): void {
            state.isProcess = false
            state.error = action.payload.error
        },
        addUnsupportedFiles(
            state: InitialStateType,
            action: PayloadAction<UnsupportedUploadPayload>
        ): void {
            state.unsuportedFiles = [
                ...state.unsuportedFiles,
                ...action.payload.files,
            ]
        },
        removeUnsupportedFiles(
            state: InitialStateType,
            action: PayloadAction<string>
        ): void {
            state.unsuportedFiles = state.unsuportedFiles.filter(
                i => i.id !== action.payload
            )
        },
        uploadPayload(
            state: InitialStateType,
            action: PayloadAction<UploadPayload>
        ): void {
            if (state.images.some(i => i.id === action.payload.file.id)) {
                state.progress[action.payload.file.id] = 0
                state.images = state.images.map(item => {
                    if (item.id === action.payload.file.id) {
                        return {
                            ...item,
                            isError: false,
                        }
                    }
                    return item
                })
            } else {
                state.images.push(action.payload.file)
            }
        },
        uploadSuccessPayload(
            state: InitialStateType,
            action: PayloadAction<UploadSuccessPayload>
        ): void {
            state.images = state.images.map(item => {
                if (item.id === action.payload.id) {
                    return {
                        ...item,
                        url: action.payload.url,
                    }
                }
                return item
            })
        },
        uploadFailurePayload(
            state: InitialStateType,
            action: PayloadAction<UploadFailurePayload>
        ): void {
            state.images = state.images.map(item => {
                if (item.id === action.payload.id) {
                    return {
                        ...item,
                        isError: true,
                    }
                }
                return item
            })
        },
        setProgressPercent(
            state: InitialStateType,
            action: PayloadAction<SetProgressPercentPayload>
        ): void {
            if (action.payload.percent > 80) {
                state.progress[action.payload.id] = action.payload.percent - 15
            } else {
                state.progress[action.payload.id] = action.payload.percent
            }
        },
        removeImageFile(
            state: InitialStateType,
            action: PayloadAction<string>
        ): void {
            state.images = state.images.filter(i => i.id !== action.payload)
        },
        destroy(): InitialStateType {
            return initialState
        },
    },
})

export const actions = selectionAssistSlice.actions
