import { siteConfig } from './lib/site-config'

export default siteConfig({
  // the site's root Notion page (required)
  rootNotionPageId: 'f27783437cc042049dbc93318ce218b6',
  // rootNotionPageId: '4fb46f90a3b24692ba0eb8679ae171f3',

  // if you want to restrict pages to a single notion workspace (optional)
  // (this should be a Notion ID; see the docs for how to extract this)
  rootNotionSpaceId: undefined,

  // basic site info (required)
  name: 'TEKARA',
  domain: process.env.NEXT_PUBLIC_DOMAIN ?? '',
  author: 'TEKARA',

  // open graph metadata (optional)
  description: 'TEKARA — маркетплейс запчастей для строительной техники',

  // default notion icon and cover images for site-wide consistency (optional)
  // page-specific values will override these site-wide defaults
  defaultPageIcon: null,
  defaultPageCover: null,
  defaultPageCoverPosition: 0.5,

  // whether or not to enable support for LQIP preview images (optional)
  isPreviewImageSupportEnabled: false,

  // whether or not redis is enabled for caching generated preview images (optional)
  // NOTE: if you enable redis, you need to set the `REDIS_HOST` and `REDIS_PASSWORD`
  // environment variables. see the readme for more info
  isRedisEnabled: true,
  redisLastSavedVersions: 3,

  // const cacheTTL = 8.64e7 // one day in milliseconds
  redisCacheTTL: null,

  // map of notion page IDs to URL paths (optional)
  // any pages defined here will override their default URL paths
  // example:
  //
  pageUrlOverrides: {
    // '/kb': 'c419b7d7de2e45e688c138eb03dab4eb',
    // '/bar': '0be6efce9daf42688f65c76b89f8eb27'
  },
  // pageUrlOverrides: null,

  databaseRootId: '4fb46f90a3b24692ba0eb8679ae171f3',
  // whether to use the default notion navigation style or a custom one with links to
  // important pages
  // navigationStyle: 'default'
  navigationStyle: 'dynamic',
  navigationSubLinkPageId: 'f2778343-7cc0-4204-9dbc-93318ce218b6',
  treeMenuRoutePageId: 'c419b7d7-de2e-45e6-88c1-38eb03dab4eb',
  // navigationLinks: [
  //   {
  //     title: 'Главная',
  //     pageId: '313363e510cb46f4b55d8971a1af9024'
  //   },
  // ]
})
