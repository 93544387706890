import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { type EnhancedStore } from '@reduxjs/toolkit/src/configureStore'
import { createWrapper } from 'next-redux-wrapper'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'

import { homePageSlice } from '../containers/HomePage/slice'
import { selectionAssistSlice } from '../containers/ModalSelectionAssist/slice'
import rootSaga from '../sagas'

const sagaMiddleware = createSagaMiddleware()

const reducer = combineReducers({
    [homePageSlice.name]: homePageSlice.reducer,
    [selectionAssistSlice.name]: selectionAssistSlice.reducer,
})

export const store = (): EnhancedStore => {
    const store = configureStore({
        reducer,
        middleware: getDefaultMiddleware => {
            if (process.env.NODE_ENV === 'development') {
                return getDefaultMiddleware({
                    serializableCheck: false,
                }).concat(sagaMiddleware, logger)
            }
            return getDefaultMiddleware({ serializableCheck: false }).concat(
                sagaMiddleware
            )
        },
    })

    sagaMiddleware.run(rootSaga)

    return store
}

export type RootState = ReturnType<typeof store>
export type AppState = ReturnType<typeof reducer>

export const wrapper = createWrapper<RootState>(store)
