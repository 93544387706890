import axios, { type AxiosResponse } from 'axios'

import { type IUploadFiles, type SendSelectionAssistPayload } from './types'

export const sendSelectionAssist = async (
    data: SendSelectionAssistPayload
): Promise<AxiosResponse> => {
    return await axios.post('/api/selection-assist', data)
}

export const uploadImage = async (
    data: IUploadFiles,
    onUploadProgress: (percent: number) => void
): Promise<AxiosResponse> => {
    const formData = new FormData()

    formData.append('formName', data.formName)

    for (const file of data.files) {
        formData.append('file', file)
    }

    return await axios({
        url: '/api/upload-media',
        method: 'post',
        headers: {
            'Content-type': 'multipart/form-data',
        },
        data: formData,
        onUploadProgress: progressEvent => {
            if (progressEvent.total) {
                let percentComplete = progressEvent.loaded / progressEvent.total
                percentComplete = parseInt((percentComplete * 100).toString())
                onUploadProgress(percentComplete)
            }
        },
    })
}
